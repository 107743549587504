import React, { useEffect, useState } from "react";
import Heading1 from "../../component/semantic/Heading1";
import ContainerOne from "../../component/common/ContainerOne";
import FormInput from "../../component/semantic/InputOne";
import BottomContent from "../../layout/BottomContent";
import DarkButton from "../../component/semantic/DarkButton";
import { useDispatch } from "react-redux";
import { handleCurrentUser, handlePartnerDetails, handrelationDetails } from "../../store/user/user.slice";
import { getAppData, getPartnerData, getUserDetails } from "../../services/request";
import useToastWrapper from "../../hooks/useToastWrapper";
import { setDataInLocalStorage } from "../../helpers/Utils";
import { useNavigate } from "react-router-dom";

const UserPolicyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToastWrapper();
  const searchParams = new URLSearchParams(window.location.search);
  const src = searchParams.get("src");
  const coupon = searchParams.get("coupon");
  const [errors, setErrors] = useState<any>({});
  const [partnerData, setPartnerData] = useState<any>({});

  const initialForm = {
    polHolderName: "",
    polHolderPhone: "",
    polHolderEmail: ""
  };

  const [policyHolderDetails, setPolicyHolderDetails] = useState<any>(initialForm);
  const fetchPartnerHandler = (src: string) => {
    getPartnerData(src)
      .then((res: any) => {
        if (res?.success) {
          let partnerData = {};
          if (res?.data?._id) {
            partnerData = {
              userCreatedBy: res?.data?._id,
              name: res?.data?.name,
              partnerLogo: res?.data?.partnerLogo,
              kypFees: 99
            };
          }
          setDataInLocalStorage("src", src);
          setPartnerData(partnerData);
          dispatch(handlePartnerDetails(partnerData));
        } else {
          localStorage.clear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (src) {
      // console.log("--------->>>", searchParams.get("src"));
      fetchPartnerHandler(src);
    } else localStorage.removeItem("src");

    if (coupon) localStorage.setItem("coupon", coupon);
    else localStorage.removeItem("coupon");

    if (searchParams.get("phone") || searchParams.get("email") || searchParams.get("name")) {
      setPolicyHolderDetails({
        ...(searchParams.get("phone") && { polHolderPhone: searchParams.get("phone") }),
        ...(searchParams.get("email") && { polHolderEmail: searchParams.get("email") }),
        ...(searchParams.get("name") && { polHolderName: searchParams.get("name") })
      });
    }
    fetchConstantData();
  }, []);

  const validateDetails = () => {
    const errObj: any = {};

    // Phone number validation
    const phoneRegex = /^[0-9]{10}$/; // Assumes 10-digit phone number
    if (!phoneRegex.test(policyHolderDetails.polHolderPhone)) {
      errObj.polHolderPhone = "should be a 10-digit number";
    }
    const phoneRegex1 = /^[6789]\d{9}$/; // Assumes 10-digit phone number
    if (!phoneRegex1.test(policyHolderDetails.polHolderPhone)) {
      errObj.polHolderPhone = "should be a valid";
    }
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    if (!emailRegex.test(policyHolderDetails.polHolderEmail)) {
      errObj.polHolderEmail = "should be a valid email address";
    }

    // Check if any field is empty
    Object.keys(policyHolderDetails).forEach((val) => {
      if (!policyHolderDetails[val]) {
        errObj[val] = "is required !!";
      }
    });

    if (Object.keys(errObj).length > 0) {
      setErrors(errObj);
      return true;
    }
    return false;
  };

  const fetchConstantData = async () => {
    const resp = await getAppData();
    if (resp && resp.success) {
      dispatch(handrelationDetails(resp.data.relations));
    }
  };

  const nextHandler = async () => {
    try {
      const isValid = validateDetails();
      if (isValid) {
        return;
      }
      let resp;
      if (partnerData?.userCreatedBy) {
        resp = await getUserDetails({ ...policyHolderDetails, polHolderEmail: policyHolderDetails?.polHolderEmail?.toLowerCase(), ...{ userCreatedBy: partnerData?.userCreatedBy } });
      } else {
        resp = await getUserDetails({ ...policyHolderDetails, polHolderEmail: policyHolderDetails?.polHolderEmail?.toLowerCase() });
      }
      if (!resp.success) return;
      if (resp?.data) {
        localStorage.setItem("userId", resp?.data?.userObj?._id);
        console.log(resp?.data?.userObj);
        dispatch(handleCurrentUser(resp?.data?.userObj));
        navigate("/otp");
        return;
      } else {
        showToast("Something went wrong !!!", "error");
      }
    } catch (e) {
      showToast("Something went wrong !!!", "error");
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    delete errors[e.target.name];
    setPolicyHolderDetails({ ...policyHolderDetails, [e.target.name]: e.target.value });
  };

  return (
    <div className="p-2 max-w-[700px] m-auto">
      <Heading1 className="font-extrabold text-sm">User Details</Heading1>
      <ContainerOne className="!bg-[unset] border border-[#D8DDE9] mt-2 !p-4">
        <FormInput error={errors?.polHolderName ? `Policyholder Name ${errors?.polHolderName}` : ""} value={policyHolderDetails.polHolderName} name="polHolderName" type="text" placeholder="Policyholder Name" label="Policyholder Name *" onChange={changeHandler} />
        <FormInput error={errors?.polHolderPhone ? `Policyholder Mobile ${errors?.polHolderPhone}` : ""} limit={10} value={policyHolderDetails.polHolderPhone} name="polHolderPhone" type="number" placeholder="Policyholder Mobile" label="Mobile Number *" onChange={changeHandler} />
        <FormInput error={errors?.polHolderEmail ? `Policyholder Email ${errors?.polHolderEmail}` : ""} value={policyHolderDetails.polHolderEmail} name="polHolderEmail" type="text" placeholder="Policyholder Email" label="Email Address *" onChange={changeHandler} />
      </ContainerOne>
      <BottomContent>
        <DarkButton label="NEXT" onclick={nextHandler} color="polifyx" buttonClassName="px-20" />
      </BottomContent>
    </div>
  );
};

export default UserPolicyDetails;
