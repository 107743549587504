// SocketComponent.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { getPolicyDetails } from "../services/request";
import { RootState } from "../store/store";
import Modal from "component/common/Modalv2";
import { handleShowProgressScreenInfo, handleUploadedPolicy } from "store/user/user.slice";

const SOCKET_URL = process.env.REACT_APP_SOCKETURL;

const SocketComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [gotoHomeModal, setGotoHomeModal] = useState(false);
  const { policy } = useSelector((state: RootState) => state.user);

  const [policyDetails, setPolicyDetails] = useState<any>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [loading, setLoading] = useState(false);
  const fetchPolicyDetails = async () => {
    const resp = await getPolicyDetails(policy?._id);

    if (resp && resp?.success) {
      dispatch(handleUploadedPolicy(resp?.data));
      setPolicyDetails(resp?.data);
      setShowModal(true);
      // navigate("/policy-details");
      // dispatch(handleRoute(3))
    }
  };
  // const goHome = ()=>{
  //     setOpenModal(false)
  //     navigate('/')
  // }
  useEffect(() => {
    if (SOCKET_URL) {
      const socket = io(SOCKET_URL || "");
      socket.on("connect", () => {
        console.log("connected to socket");

        socket.on("KYP_RESULT_READY", async (data: any) => {
          try {
            // console.log(data, "User Id Recieved from webhook");
            if (data.userId) {
              fetchPolicyDetails();
            } else {
              setGotoHomeModal(true);
              // navigate("/tryAgain");
            }
          } catch (error) {
            console.log(error, "-------from socket");
          }
        });
      });

      return () => {
        socket.disconnect(); // Clean up the socket connection
      };
    }
  }, []);

  const nextPageHandler = async () => {
    if (policyDetails?.insuranceType?._id === "3030b68f-82e9-4dfb-b2b8-82b743d0692a") return navigate("/health-policy/" + policy?._id);
    else navigate("/policy-details/" + policy?._id);
  };

  const handleContinueWatching = () => {
    dispatch(handleShowProgressScreenInfo(true));
    setShowModal(!showModal);
  };

  return (
    <>
      <Modal isOpen={showModal} onClose={() => setShowModal(!showModal)} className="w-[30rem]">
        <div className="px-5 py-3 font-bold text-base">Policy Reading has been completed. Are you ready to move on to the next step?</div>
        <div className="bg-[#F0F4FD] p-4 mt-2 flex justify-around gap-5">
          <button onClick={handleContinueWatching} className={`border-[1.5px] w-1/2 flex items-center justify-center gap-2 border-black h-12 hover:bg-black hover:text-white rounded-lg min-w-44 md:min-w-52 font-bold `}>
            Continue Watching
          </button>
          <button className={`flex w-1/2 justify-center mx-auto bg-polifyx gap-2 items-center  py-3 rounded-lg font-bold`} onClick={nextPageHandler}>
            <p>Yes</p>
          </button>
        </div>
      </Modal>

      <Modal isOpen={gotoHomeModal} onClose={() => setGotoHomeModal(!gotoHomeModal)} className="w-[30rem]">
        <div className="px-5 py-3 pt-0 font-bold text-base">Your Policy quality check will be available in 24 hours.</div>
        <div className="bg-[#F0F4FD] p-4 mt-2 flex justify-around gap-5">
          <button className={`flex w-1/2 justify-center mx-auto bg-polifyx gap-2 items-center  py-3 rounded-lg font-bold `} onClick={() => navigate("/existing-policy")}>
            Go to Home
          </button>
        </div>
      </Modal>
    </>
  ); // This component doesn't render anything visible
};

export default SocketComponent;
